<div class="container">
  <div class="group-form row">
    <div class="col-12">

      <div *ngIf="activeEPerson$ | async; then editHeader; else createHeader"></div>

      <ng-template #createHeader>
        <h1 class="border-bottom pb-2">{{messagePrefix + '.create' | translate}}</h1>
      </ng-template>

      <ng-template #editHeader>
        <h1 class="border-bottom pb-2">{{messagePrefix + '.edit' | translate}}</h1>
      </ng-template>

      <ds-form [formId]="formId"
               [formModel]="formModel"
               [formGroup]="formGroup"
               [formLayout]="formLayout"
               [displayCancel]="false"
               [submitLabel]="submitLabel"
               (submitForm)="onSubmit()">
        <div before class="btn-group">
          <button (click)="onCancel()" type="button" class="btn btn-outline-secondary">
            <i class="fas fa-arrow-left"></i> {{messagePrefix + '.return' | translate}}
          </button>
        </div>
        <div *ngIf="displayResetPassword" between class="btn-group">
          <button class="btn btn-primary" [dsBtnDisabled]="!(canReset$ | async)" type="button" (click)="resetPassword()">
            <i class="fa fa-key"></i> {{'admin.access-control.epeople.actions.reset' | translate}}
          </button>
        </div>
        <div *ngIf="canImpersonate$ | async" between class="btn-group">
          <button *ngIf="!isImpersonated" class="btn btn-primary" type="button" (click)="impersonate()">
            <i class="fa fa-user-secret"></i> {{'admin.access-control.epeople.actions.impersonate' | translate}}
          </button>
          <button *ngIf="isImpersonated" class="btn btn-primary" type="button" (click)="stopImpersonating()">
            <i class="fa fa-user-secret"></i> {{'admin.access-control.epeople.actions.stop-impersonating' | translate}}
          </button>
        </div>
        <button *ngIf="canDelete$ | async" after class="btn btn-danger delete-button" type="button" (click)="delete()">
          <i class="fas fa-trash"></i> {{'admin.access-control.epeople.actions.delete' | translate}}
        </button>
      </ds-form>

      <ds-themed-loading [showMessage]="false" *ngIf="!formGroup"></ds-themed-loading>

      <div *ngIf="activeEPerson$ | async">
        <h2>{{messagePrefix + '.groupsEPersonIsMemberOf' | translate}}</h2>

        <ds-themed-loading [showMessage]="false" *ngIf="!(groups | async)"></ds-themed-loading>

        <ds-pagination
          *ngIf="(groups | async)?.payload?.totalElements > 0"
          [paginationOptions]="config"
          [collectionSize]="(groups | async)?.payload?.totalElements"
          [hideGear]="true"
          [hidePagerWhenSinglePage]="true"
          (pageChange)="onPageChange($event)">

          <div class="table-responsive">
            <table id="groups" class="table table-striped table-hover table-bordered">
              <thead>
              <tr>
                <th scope="col" class="align-middle">{{messagePrefix + '.table.id' | translate}}</th>
                <th scope="col" class="align-middle">{{messagePrefix + '.table.name' | translate}}</th>
                <th scope="col" class="align-middle">{{messagePrefix + '.table.collectionOrCommunity' | translate}}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let group of (groups | async)?.payload?.page">
                <td class="align-middle">{{group.id}}</td>
                <td class="align-middle">
                  <a (click)="groupsDataService.startEditingNewGroup(group)"
                     [routerLink]="[groupsDataService.getGroupEditPageRouterLink(group)]">
                    {{ dsoNameService.getName(group) }}
                  </a>
                </td>
                <td class="align-middle">
                  {{ dsoNameService.getName((group.object | async)?.payload) }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </ds-pagination>

        <div *ngIf="(groups | async)?.payload?.totalElements == 0" class="alert alert-info w-100 mb-2" role="alert">
          <div>{{messagePrefix + '.memberOfNoGroups' | translate}}</div>
          <div>
            <button [routerLink]="[groupsDataService.getGroupRegistryRouterLink()]"
                    class="btn btn-primary">{{messagePrefix + '.goToGroups' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
