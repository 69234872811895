<div class="item-bitstreams" *ngVar="(bundles$ | async) as bundles">
  <div class="mt-2" id="reorder-description">
    <ds-alert [content]="'item.edit.bitstreams.info-alert'" [type]="AlertType.Info"></ds-alert>
  </div>

  <div class="button-row top d-flex mt-2 space-children-mr">
    <button class="mr-auto btn btn-success"
            [attr.aria-label]="'item.edit.bitstreams.upload-button' | translate"
            [routerLink]="[itemPageRoute, 'bitstreams', 'new']"><i
            class="fas fa-upload"></i>
      <span class="d-none d-sm-inline">&nbsp;{{"item.edit.bitstreams.upload-button" | translate}}</span>
    </button>
    <button class="btn btn-warning" *ngIf="isReinstatable$ | async"
            [attr.aria-label]="'item.edit.bitstreams.reinstate-button' | translate"
            (click)="reinstate()"><i
            class="fas fa-undo-alt"></i>
      <span class="d-none d-sm-inline">&nbsp;{{"item.edit.bitstreams.reinstate-button" | translate}}</span>
    </button>
    <button class="btn btn-primary" [dsBtnDisabled]="(hasChanges$ | async) !== true || submitting"
            [attr.aria-label]="'item.edit.bitstreams.save-button' | translate"
            (click)="submit()"><i
            class="fas fa-save"></i>
      <span class="d-none d-sm-inline">&nbsp;{{"item.edit.bitstreams.save-button" | translate}}</span>
    </button>
    <button class="btn btn-danger" *ngIf="(isReinstatable$ | async) !== true"
            [attr.aria-label]="'item.edit.bitstreams.discard-button' | translate"
            [dsBtnDisabled]="(hasChanges$ | async) !== true || submitting"
            (click)="discard()"><i
            class="fas fa-times"></i>
      <span class="d-none d-sm-inline">&nbsp;{{"item.edit.bitstreams.discard-button" | translate}}</span>
    </button>
  </div>

  <div *ngIf="item && bundles?.length > 0" class="mt-4 table-border scrollable-table" [ngClass]="{'disabled-overlay': (isProcessingMoveRequest | async)}">
    <ds-item-edit-bitstream-bundle *ngFor="let bundle of bundles; first as isFirst"
                                   [bundle]="bundle"
                                   [item]="item"
                                   [columnSizes]="columnSizes"
                                   [isFirstTable]="isFirst"
                                   aria-describedby="reorder-description">
    </ds-item-edit-bitstream-bundle>
    <div class="d-flex justify-content-center" *ngIf="showLoadMoreLink$ | async">
      <button class="btn btn-link my-3" (click)="loadBundles()"> {{'item.edit.bitstreams.load-more.link' | translate}}</button>
    </div>
  </div>
  <div *ngIf="bundles?.length === 0"
       class="alert alert-info w-100 d-inline-block mt-4" role="alert">
    {{'item.edit.bitstreams.empty' | translate}}
  </div>
  <ds-themed-loading *ngIf="!bundles" message="{{'loading.bitstreams' | translate}}"></ds-themed-loading>

  <div class="button-row bottom">
    <div class="mt-4 float-right space-children-mr ml-gap">
      <button class="btn btn-warning" *ngIf="isReinstatable$ | async"
              [attr.aria-label]="'item.edit.bitstreams.reinstate-button' | translate"
              (click)="reinstate()"><i
        class="fas fa-undo-alt"></i>
        <span class="d-none d-sm-inline">&nbsp;{{"item.edit.bitstreams.reinstate-button" | translate}}</span>
      </button>
      <button class="btn btn-primary" [dsBtnDisabled]="(hasChanges$ | async) !== true || submitting"
              [attr.aria-label]="'item.edit.bitstreams.save-button' | translate"
              (click)="submit()"><i
        class="fas fa-save"></i>
        <span class="d-none d-sm-inline">&nbsp;{{"item.edit.bitstreams.save-button" | translate}}</span>
      </button>
      <button class="btn btn-danger" *ngIf="(isReinstatable$ | async) !== true"
              [attr.aria-label]="'item.edit.bitstreams.discard-button' | translate"
              [dsBtnDisabled]="(hasChanges$ | async) !== true || submitting"
              (click)="discard()"><i
        class="fas fa-times"></i>
        <span class="d-none d-sm-inline">&nbsp;{{"item.edit.bitstreams.discard-button" | translate}}</span>
      </button>
    </div>
  </div>
</div>

<ds-themed-loading *ngIf="isProcessingMoveRequest | async" class="loading-overlay"></ds-themed-loading>
