<ds-themed-results-back-button *ngIf="showBackButton | async" [back]="back"></ds-themed-results-back-button>
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer  id="iiif-viewer"
                        [object]="object"
                        [searchable]="iiifSearchEnabled"
                        [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <ds-dso-edit-menu></ds-dso-edit-menu>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!(mediaViewer.image || mediaViewer.video)">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <div *ngIf="mediaViewer.image || mediaViewer.video" class="mb-2">
      <ds-themed-media-viewer [item]="object"></ds-themed-media-viewer>
    </div>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
                                            [parentItem]="object"
                                            [itemType]="'Person'"
                                            [metadataFields]="['dc.creator']"
                                            [label]="'relationships.isAuthorOf' | translate">
    </ds-themed-metadata-representation-list>

    <!-- SUB Hamburg / eWW -->
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
                                            [parentItem]="object"
                                            [itemType]="'Person'"
                                            [metadataFields]="['dc.contributor.other']"
                                            [label]="'relationships.isCoAuthorOf' | translate">
    </ds-themed-metadata-representation-list>

    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
                                            [parentItem]="object"
                                            [itemType]="'Person'"
                                            [metadataFields]="['dc.contributor.editor']"
                                            [label]="'relationships.isEditorOf' | translate">
    </ds-themed-metadata-representation-list>

    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
                                            [parentItem]="object"
                                            [itemType]="'Person'"
                                            [metadataFields]="['dc.contributor']"
                                            [label]="'relationships.isContributorOf' | translate">
    </ds-themed-metadata-representation-list>

    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
                                            [parentItem]="object"
                                            [itemType]="'Person'"
                                            [metadataFields]="['dc.contributor.advisor']"
                                            [label]="'relationships.isAdvisorOf' | translate">
    </ds-themed-metadata-representation-list>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.identifier.uri']"
                                [label]="'publication.page.uri'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.identifier.doi']"
                                [label]="'publication.page.doi'">
    </ds-generic-item-page-field>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <!-- SUB Hamburg / eWW -->
  </div>
  <div class="col-xs-12 col-md-7">
    <!-- SUB Hamburg / eWW -->
    <div class="icon-wrapper">
      <div class="tooltip-container">
        <img class="openaccess-icon" src="assets/sub/images/openaccess.png" alt="Open access" title="Open access" />
        <span class="tooltip-text">Open access</span>
      </div>

      <div class="tooltip-container">
        <img class="document-icon" src="assets/sub/images/document.png" alt="Volltextdatei(en) vorhanden" />
        <span class="tooltip-text">Volltextdatei(en) vorhanden</span>
      </div>

      <div class="tooltip-container">
        <div class="cc-badge">
          <a href="{{ ccBadge.url }}" target="_blank">
            <img src="assets/sub/images/cc-badges-svg/{{ ccBadge.img }}" alt="{{ ccBadge.url }}" />
          </a>
        </div>
        <span class="tooltip-text">{{ ccBadge.url }}</span>
      </div>
    </div>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.type']"
                                [label]="'publication.page.type'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dcterms.bibliographicCitation.booktitle', 'dc.source']"
                                [label]="'publication.page.source'">
    </ds-generic-item-page-field>
    <!-- SUB Hamburg / eWW -->
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <!-- SUB Hamburg / eWW -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.subject.ddc']"
                                [label]="'publication.page.ddc'">
    </ds-generic-item-page-field>
    <!-- SUB Hamburg / eWW -->
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfPublication'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>

    <div class="item-page-field">
      <ds-metadata-field-wrapper _ngcontent-dspace-angular-c222="" _nghost-dspace-angular-c162="" ng-reflect-label="Zitationsvorschlag">
      <div _ngcontent-dspace-angular-c162="" class="simple-view-element">
        <h2 _ngcontent-dspace-angular-c162="" class="simple-view-element-header ng-star-inserted">
          Zitationsvorschlag
        </h2>
        <div class="simple-view-element-body">
          <div class="citations">
            <ul>
              <li *ngFor="let item of citations; let i = index">
                <div (click)="toggleDetails(i)" class="citation-title">
                  {{ item.title }}
                </div>
                <div *ngIf="item.showDetails" class="citation-details" [innerHtml]="item.details">
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </ds-metadata-field-wrapper>
    </div>

    <ds-metadata-field-wrapper _nghost-dspace-angular-c162="" ng-reflect-label="Metadatenexport">
      <ds-metadata-field-wrapper _ngcontent-dspace-angular-c162="" class="simple-view-element">
        <h2 _ngcontent-dspace-angular-c162="" class="simple-view-element-header ng-star-inserted">Metadatenexport</h2>
        <div class="metadata-export-button-group">
          <select class="metadata-export-select" [(ngModel)]="selectedFormat">
            <option value="bibtex">BibTeX</option>
            <option value="endnote">Endnote</option>
            <option value="csv">CSV</option>
          </select>
          <button class="metadata-export-button" (click)="downloadFile(selectedFormat)">Download</button>
        </div>
      </ds-metadata-field-wrapper>
    </ds-metadata-field-wrapper>

    <div *ngIf="usageStats" class="item-page-field">
      <ds-metadata-field-wrapper _ngcontent-dspace-angular-c222="" _nghost-dspace-angular-c162="" ng-reflect-label="Zitationsvorschlag">
        <div _ngcontent-dspace-angular-c162="" class="simple-view-element">
          <h2 _ngcontent-dspace-angular-c162="" class="simple-view-element-header ng-star-inserted">
            Statistiken
          </h2>
          <div class="simple-view-element-body">
            <div class="usage-stats" *ngIf="reportData.length > 0">
              <div *ngFor="let report of reportData">
                <div *ngIf="report['points'].length > 0">
                  <b>{{ report['report-type'] }}</b>
                  <div *ngFor="let reportPoint of report['points']" class="usage-stats-points-wrapper">
                    <div>
                      <span class="usage-stats-points-label">{{ reportPoint['label'] }}:</span> <span class="usage-stats-points-values">{{ reportPoint['values']['views'] }}</span></div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="reportData.length === 0">
              <p>No usage reports available.</p>
            </div>
          </div>
        </div>
      </ds-metadata-field-wrapper>
    </div>

    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
