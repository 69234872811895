import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { PublicationComponent as BaseComponent } from '../../../../../../../app/item-page/simple/item-types/publication/publication.component';
import * as  CiteProc from 'citeproc';
import { HttpClient } from '@angular/common/http';
import {RouteService} from "../../../../../../../app/core/services/route.service";
import {Router} from "@angular/router";

import {environment} from "../../../../../../../environments/environment";
import {Observable, of as observableOf} from "rxjs";
import {EPerson} from "../../../../../../../app/core/eperson/models/eperson.model";
import {map, switchMap, take} from "rxjs/operators";
import {AuthService} from "../../../../../../../app/core/auth/auth.service";
import {RoleService} from "../../../../../../../app/core/roles/role.service";
import {hasValue} from "../../../../../../../app/shared/empty.util";
import {Item} from "../../../../../../../app/core/shared/item.model";
import {EPersonDataService} from "../../../../../../../app/core/eperson/eperson-data.service";
import {ItemDataService} from "../../../../../../../app/core/data/item-data.service";

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Publication', ViewMode.StandalonePage, Context.Any, 'sub')
@Component({
  selector: 'ds-publication',
  styleUrls: ['./publication.component.scss'],
  // styleUrls: ['../../../../../../../app/item-page/simple/item-types/publication/publication.component.scss'],
  templateUrl: './publication.component.html',
  // templateUrl: '../../../../../../../app/item-page/simple/item-types/publication/publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationComponent extends BaseComponent {

  /**
   * SUB Hamburg / eWW
   * CC badge object to display info on publication page
   */
  public ccBadge = {
    'url' : '',
    'img' : '',
  };

  private citationStyles: { [key: string]: string } = {
    "DIN_1505-2": "din-1505-2",
    "APA": "apa",
  };

  public citations = [];

  private apiUrl = `${environment.rest.baseUrl}/api/metadata-export/`; // Adjust if needed
  selectedFormat: string = 'bibtex';

  public usageStats: any;
  public reportData: any[] = [];

  constructor(protected routeService: RouteService,
              protected router: Router,
              private http: HttpClient,
              private auth: AuthService,
              protected roleService: RoleService,
              private epersonService: EPersonDataService,
              private itemDataService: ItemDataService) {
    super(routeService, router);
  }

  /**
   * SUB Hamburg / eWW
   * Added call to ngOnInit() to initialize CC badges
   */
  ngOnInit() {
    super.ngOnInit();
    this.initCcBadges();
    this.initCitations();
    this.initStatistics();
  }

  /**
   * SUB Hamburg / eWW
   * Initialize CC badges and links
   */
  initCcBadges () {
    const badges = {
      'https://creativecommons.org/publicdomain/zero/1.0/' : ['cc-zero.svg'],
      'https://creativecommons.org/licenses/by/4.0/' : ['by.svg'],
      'https://creativecommons.org/licenses/by-sa/4.0/' : ['by-sa.svg'],
      'https://creativecommons.org/licenses/by-nd/4.0/' : ['by-nd.svg'],
      'https://creativecommons.org/licenses/by-nc/4.0/' : ['by-nc.eu.svg'],
      'https://creativecommons.org/licenses/by-nc-sa/4.0/' : ['by-nc-sa.eu.svg'],
      'https://creativecommons.org/licenses/by-nc-nd/4.0/' : ['by-nc-nd.eu.svg'],
    };
    for (const metadataField of Object.keys(this.object.metadata)) {
      if (metadataField == 'dc.rights') {
        this.ccBadge = {
          'url' : this.object.metadata[metadataField][0]['value'],
          'img' : badges[this.object.metadata[metadataField][0]['value']],
        };
      }
    }
  }

  /**
   * SUB Hamburg / eWW
   * Initialize citations
   */
  initCitations () {

    const citationItems = {
      "item": {
        id: "item",
      },
    };

    for (var citationStyleKey in this.citationStyles) {
      let dcType = '';
      const dcTypeMetadata = this.getMetadataForKey('dc.type');
      if (dcTypeMetadata.length > 0) {
        dcType = dcTypeMetadata[0];
        citationItems['item']['type'] = dcType.toLowerCase();
      }

      const dcCreator = this.getMetadataForKey('dc.creator')
      if (dcCreator.length > 0) {
        for (var dcCreatorItem of dcCreator) {
          var valueArrAuthor = [dcCreatorItem];
          if (dcCreatorItem.includes(',')) {
            valueArrAuthor = dcCreatorItem.split(',');
          }
          if (!citationItems['item']['author']) {
            citationItems['item']['author'] = [];
          }
          var tempArrAuthor: { family: string; given?: string } = {
            family: valueArrAuthor[0].trim(),
            given: valueArrAuthor.length > 1 ? valueArrAuthor[1].trim() : undefined
          };
          citationItems['item']['author'].push(tempArrAuthor);
          if (!citationItems['item']['author_uppercase']) {
            citationItems['item']['author_uppercase'] = [];
          }
          var tempArrAuthorUpperCase: { family: string; given?: string } = {
            family: valueArrAuthor[0].trim().toUpperCase(),
            given: valueArrAuthor.length > 1 ? valueArrAuthor[1].trim() : undefined
          };
          citationItems['item']['author_uppercase'].push(tempArrAuthorUpperCase);
        }
      }

      const dcContributorOther = this.getMetadataForKey('dc.contributor.other')
      if (dcContributorOther.length > 0) {
        for (var dcContributorOtherItem of dcContributorOther) {
          var valueArrOther = [dcContributorOtherItem];
          if (dcContributorOtherItem.includes(',')) {
            valueArrOther = dcContributorOtherItem.split(',');
          }
          if (!citationItems['item']['author']) {
            citationItems['item']['author'] = [];
          }
          var tempArrOther: { family: string; given?: string } = {
            family: valueArrOther[0].trim(),
            given: valueArrOther.length > 1 ? valueArrOther[1].trim() : undefined
          };
          citationItems['item']['author'].push(tempArrOther);
          if (!citationItems['item']['author_uppercase']) {
            citationItems['item']['author_uppercase'] = [];
          }
          var tempArrOtherUpperCase: { family: string; given?: string } = {
            family: valueArrAuthor[0].trim().toUpperCase(),
            given: valueArrAuthor.length > 1 ? valueArrAuthor[1].trim() : undefined
          };
          citationItems['item']['author_uppercase'].push(tempArrOtherUpperCase);
        }
      }

      // Remove duplicate authors
      citationItems['item']['author'] = citationItems['item']['author'].filter((author, index, self) =>
        index === self.findIndex(a => a.family === author.family && a.given === author.given)
      );

      if (['BookPart'].includes(dcType)) {
        const dcContributorEditor = this.getMetadataForKey('dc.contributor.editor')
        if (dcContributorEditor.length > 0) {
          for (var dcContributorEditorItem of dcContributorEditor) {
            var valueArrEditor = [dcContributorEditorItem];
            if (dcContributorEditorItem.includes(',')) {
              valueArrEditor = dcContributorEditorItem.split(',');
            }
            if (!citationItems['item']['editor']) {
              citationItems['item']['editor'] = [];
            }
            var tempArrEditor: { family: string; given?: string } = {
              family: valueArrEditor[0].trim(),
              given: valueArrEditor.length > 1 ? valueArrEditor[1].trim() : undefined
            };
            citationItems['item']['editor'].push(tempArrEditor);
            if (!citationItems['item']['editor_uppercase']) {
              citationItems['item']['editor_uppercase'] = [];
            }
            var tempArrEditorUpperCase: { family: string; given?: string } = {
              family: valueArrAuthor[0].trim().toUpperCase(),
              given: valueArrAuthor.length > 1 ? valueArrAuthor[1].trim() : undefined
            };
            citationItems['item']['editor_uppercase'].push(tempArrEditorUpperCase);
          }
        }
      }

      const dcTitle = this.getMetadataForKey('dc.title')
      if (dcTitle.length > 0) {
        for (var dcTitleItem of dcTitle) {
          citationItems['item']['title'] = dcTitleItem;

          if (!['Article', 'ResearchArticle', 'ContributionToPeriodical', 'BookPart'].includes(dcType)) {
            const dcTitleSubtitle = this.getMetadataForKey('dc.title.subtitle')
            for (var dcTitleSubtitleItem of dcTitleSubtitle) {
              if (dcTitleSubtitle.length > 0) {
                citationItems['item']['title'] += ' : ' + dcTitleSubtitleItem;
              }
            }
          }
        }
      }

      const dcDateAvailable = this.getMetadataForKey('dc.date.available')
      if (dcDateAvailable.length > 0) {
        for (var dcDateAvailableItem of dcDateAvailable) {
          var valueArrDateAvailable = dcDateAvailableItem.split('-');
          citationItems['item']['issued'] = {};
          citationItems['item']['issued']['date-parts'] = [valueArrDateAvailable];
        }
      }

      if (['Article', 'ResearchArticle', 'ContributionToPeriodical', 'BookPart'].includes(dcType)) {
        const dctermsBibliographicCitationBooktitle = this.getMetadataForKey('dcterms.bibliographicCitation.booktitle')
        if (dctermsBibliographicCitationBooktitle.length > 0) {
          for (var dctermsBibliographicCitationBooktitleItem of dctermsBibliographicCitationBooktitle) {
            citationItems['item']['container-title'] = dctermsBibliographicCitationBooktitleItem;
          }
        }
      }

      if (['Article', 'ResearchArticle', 'ContributionToPeriodical', 'BookPart'].includes(dcType)) {
        const dctermsBibliographicCitationPagestart = this.getMetadataForKey('dcterms.bibliographicCitation.pagestart')
        const dctermsBibliographicCitationPageend = this.getMetadataForKey('dcterms.bibliographicCitation.pageend')
        if (dctermsBibliographicCitationPagestart.length > 0) {
          let page = '';
          for (var dctermsBibliographicCitationPagestartItem of dctermsBibliographicCitationPagestart) {
            page += dctermsBibliographicCitationPagestartItem;
          }
          if (dctermsBibliographicCitationPageend.length > 0) {
            for (var dctermsBibliographicCitationPageendItem of dctermsBibliographicCitationPageend) {
              page += '-' + dctermsBibliographicCitationPageendItem;
            }
          }
          citationItems['item']['page'] = page;
        }
      }

      if (citationStyleKey != 'DIN_1505-2' &&  !['Article', 'ResearchArticle', 'ContributionToPeriodical', 'Monograph', 'EditedCollection', 'Book', 'DoctoralThesis', 'PhDThesis', 'Habilitation', 'BookPart'].includes(dcType)) {
        const dcPublisher = this.getMetadataForKey('dc.publisher')
        if (dcPublisher.length > 0) {
          for (var dcPublisherItem of dcPublisher) {
            citationItems['item']['publisher'] = dcPublisherItem;
          }
        }
      }

      const dcIdentifierDoi = this.getMetadataForKey('dc.identifier.doi')
      if (dcIdentifierDoi.length > 0) {
        for (var dcIdentifierDoiItem of dcIdentifierDoi) {
          citationItems['item']['DOI'] = dcIdentifierDoiItem;
        }
      }

      const dcIdentifierUrl = this.getMetadataForKey('dc.identifier.url')
      if (dcIdentifierUrl.length > 0) {
        for (var dcIdentifierUrlItem of dcIdentifierUrl) {
          citationItems['item']['URL'] = dcIdentifierUrlItem;
        }
      }

      const dcIdentifierUri = this.getMetadataForKey('dc.identifier.uri')
      if (dcIdentifierUri.length > 0) {
        for (var dcIdentifierUriItem of dcIdentifierUri) {
          citationItems['item']['URL'] = dcIdentifierUriItem;
        }
      }

      const getCurrentDate = (): (number | string)[] => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return [year, month, day];
      };
      citationItems['item']['accessed'] = {};
      citationItems['item']['accessed']['date-parts'] = [getCurrentDate()];

      const citeprocEngine = this.getProcessor(this.citationStyles[citationStyleKey], citationItems);
      citeprocEngine.updateItems(["item"]);
      const bibliography = citeprocEngine.makeBibliography();
      this.citations.push({ title: citationStyleKey, details: bibliography[1][0], showDetails: false });
    }
  }

  toggleDetails(index: number) {
    this.citations[index].showDetails = !this.citations[index].showDetails;
  }

  getProcessor(styleID, citationItems) {
    const citeprocSys = {
      retrieveLocale: function (lang){
        var xhr = new XMLHttpRequest();
        xhr.open('GET', '/assets/sub/locales/locales-' + lang + '.xml', false);
        xhr.send(null);
        return xhr.responseText;
      },
      retrieveItem: function(id){
        return citationItems[id];
      }
    };

    var xhr = new XMLHttpRequest();
    xhr.open('GET', '/assets/sub/styles/' + styleID + '.csl', false);
    xhr.send(null);
    var styleAsText = xhr.responseText;
    return new CiteProc.Engine(citeprocSys, styleAsText);
  };

  getMetadataForKey (metadataKey) {
    const result = [];
    for (var metadataFieldKey of Object.keys(this.object.metadata)) {
      if (metadataFieldKey == metadataKey) {
        for (var metadataFieldValue of this.object.metadata[metadataFieldKey]) {
            result.push(metadataFieldValue['value']);
        }
      }
    }
    return result;
  }

  downloadFile(format: string): void {
    this.http.get(this.apiUrl+this.object.uuid+'/'+format, { responseType: 'blob' }).subscribe({
      next: (blob) => {
        let fileName: string;
        switch (format) {
          case 'bibtex':
            fileName = 'export.bib';
            break;
          case 'endnote':
            fileName = 'export.enw';
            break;
          case 'csv':
            fileName = 'export.csv';
            break;
          default:
            fileName = 'export.txt';
            break;
        }

        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      },
      error: (error) => {
        console.error('Download error:', error);
      }
    });
  }

  initStatistics () {
    const statisticUrl = `${environment.rest.baseUrl}/api/statistics/usagereports/search/object?uri=${environment.rest.baseUrl}/api/core/item/${this.object.uuid}`;
    this.http.get(statisticUrl).subscribe((stats) => {
      this.usageStats = stats;
      if (this.usageStats._embedded && this.usageStats._embedded.usagereports) {
        this.reportData = this.usageStats._embedded.usagereports;
      }
    });
  }
}
