<div ngbDropdown class="navbar-nav" *ngIf="moreThanOneLanguage" display="dynamic" placement="bottom-right">
  <a href="javascript:void(0);" role="menuitem"
     [attr.aria-label]="'nav.language' |translate"
     aria-controls="language-menu-list"
     aria-haspopup="menu"
     [title]="'nav.language' | translate"
     (click)="$event.preventDefault()" data-toggle="dropdown" ngbDropdownToggle
     data-test="lang-switch"
     tabindex="0">
    <i class="fas fa-globe-asia fa-lg fa-fw"></i>
  </a>
  <ul ngbDropdownMenu class="dropdown-menu" [attr.aria-label]="'nav.language' |translate" id="language-menu-list" role="menu">
    <li class="dropdown-item" tabindex="0" #langSelect *ngFor="let lang of translate.getLangs()"
        role="menuitem"
            (keyup.enter)="useLang(lang)"
            (click)="useLang(lang)"
            [class.active]="lang === translate.currentLang">
                {{ langLabel(lang) }}
    </li>
  </ul>
</div>
