<div [formGroup]="group" class="input-group">

    <input ngbDatepicker class="form-control" #datepicker="ngbDatepicker"
           [attr.aria-labelledby]="'label_' + model.id"
           [class.is-invalid]="showErrorMessages"
           [displayMonths]="model.getAdditional('displayMonths', config['displayMonths'])"
           [id]="id"
           [firstDayOfWeek]="model.getAdditional('firstDayOfWeek', config['firstDayOfWeek'])"
           [formControlName]="model.id"
           [maxDate]="model.max"
           [minDate]="model.min"
           [name]="model.name"
           [navigation]="model.getAdditional('navigation', config['navigation'])"
           [ngClass]="getClass('element', 'control')"
           [outsideDays]="model.getAdditional('outsideDays', config['outsideDays'])"
           [placeholder]="model.placeholder"
           [placement]="model.getAdditional('placement', 'bottom-left')"
           [weekdays]="model.getAdditional('showWeekdays', config['showWeekdays'])"
           [showWeekNumbers]="model.getAdditional('showWeekNumbers', config['showWeekNumbers'])"
           [startDate]="model.focusedDate"
           (blur)="onBlur($event)"
           (dateSelect)="onChange($event)"
           (change)="onChange($event)"
           (focus)="onFocus($event)">

    <div class="input-group-append">

        <button class="btn btn-outline-secondary"
                type="button"
                [attr.aria-labelledby]="'label_' + model.id"
                [class.disabled]="model.disabled"
                [dsBtnDisabled]="model.disabled"
                (click)="datepicker.toggle()">

            <i *ngIf="model.toggleIcon" class="{{model.toggleIcon}}" aria-hidden="true"></i>
            <span *ngIf="model.toggleLabel">{{ model.toggleLabel }}</span>

        </button>

    </div>

</div>
