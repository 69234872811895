<ng-template #bundleView>

  <ds-pagination *ngIf="(bitstreamsRD$ | async)?.payload as bitstreamsList"
                 [hideGear]="true"
                 [hidePagerWhenSinglePage]="true"
                 [hidePaginationDetail]="true"
                 [paginationOptions]="paginationOptions"
                 [collectionSize]="bitstreamsList.totalElements"
                 [retainScrollPosition]="true"
                 [ngbTooltip]="'item.edit.bitstreams.bundle.tooltip' | translate" placement="bottom"
                 [autoClose]="false" triggers="manual" #dragTooltip="ngbTooltip">
    <ng-container *ngIf="(updates$ | async) as updates">

      <table class="table" [class.mt-n1]="!isFirstTable"
             [attr.aria-label]="'item.edit.bitstreams.bundle.table.aria-label' | translate: { bundle: bundleName } ">
        <thead [class.visually-hidden]="!isFirstTable">
        <tr class="header-row font-weight-bold">
          <th id="name" scope="col" class="{{ columnSizes.columns[0].buildClasses() }}">
            {{'item.edit.bitstreams.headers.name' | translate}}
          </th>
          <th id="description" scope="col" class="{{ columnSizes.columns[1].buildClasses() }}">
            {{'item.edit.bitstreams.headers.description' | translate}}
          </th>
          <th id="format" scope="col" class="{{ columnSizes.columns[2].buildClasses() }}">
            {{'item.edit.bitstreams.headers.format' | translate}}
          </th>
          <th id="actions" scope="col" class="{{ columnSizes.columns[3].buildClasses() }} text-center">
            {{'item.edit.bitstreams.headers.actions' | translate}}
          </th>
        </tr>
        </thead>

        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
        <tr class="bundle-row">
          <th id="{{ bundleName }}" class="span" colspan="3" scope="colgroup">
            {{'item.edit.bitstreams.bundle.name' | translate:{ name: bundleName } }}
          </th>
          <td class="text-center">

            <div class="btn-group">
              <button [routerLink]="[itemPageRoute, 'bitstreams', 'new']"
                      [queryParams]="{bundle: bundle.id}"
                      [attr.aria-label]="'item.edit.bitstreams.bundle.edit.buttons.upload' | translate"
                      class="btn btn-outline-success btn-sm"
                      title="{{'item.edit.bitstreams.bundle.edit.buttons.upload' | translate}}">
                <i class="fas fa-upload fa-fw"></i>
              </button>
              <div ngbDropdown #paginationControls="ngbDropdown" class="btn-group float-right btn-sm p-0"
                   placement="bottom-right">
                <button class="btn btn-outline-secondary" id="paginationControls" ngbDropdownToggle
                        [title]="'pagination.options.description' | translate"
                        [attr.aria-label]="'pagination.options.description' | translate" aria-haspopup="true"
                        aria-expanded="false">
                  <i class="fas fa-cog" aria-hidden="true"></i>
                </button>

                <ul id="paginationControlsDropdownMenu" aria-labelledby="paginationControls" role="menu"
                    ngbDropdownMenu>
                  <li role="menuitem">
                    <span class="dropdown-header" id="pagination-control_results-per-page"
                          role="heading">{{ 'pagination.results-per-page' | translate}}</span>
                    <ul aria-labelledby="pagination-control_results-per-page" class="list-unstyled" role="listbox">
                      <li *ngFor="let size of paginationOptions.pageSizeOptions" role="option"
                          [attr.aria-selected]="size === (pageSize$ | async)">
                        <button (click)="doPageSizeChange(size)" class="dropdown-item">
                          <i [ngClass]="{'invisible': size !== (pageSize$ | async) }" class="fas fa-check"
                             aria-hidden="true"></i> {{size}}
                        </button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

          </td>
        </tr>

        <ng-container *ngFor="let entry of (tableEntries$ | async)">
          <tr *ngIf="updates[entry.id] as update" [ngClass]="getRowClass(update, entry)" class="bitstream-row" cdkDrag
              (cdkDragStarted)="dragStart()" (cdkDragEnded)="dragEnd()">

            <th class="bitstream-name row-element {{ columnSizes.columns[0].buildClasses() }}"
                scope="row" id="{{ entry.nameStripped }}" headers="{{ bundleName }} name">
              <div class="drag-handle text-muted float-left p-1 mr-2" tabindex="0" cdkDragHandle
                   (keydown.enter)="select($event, entry)" (keydown.space)="select($event, entry)" (click)="select($event, entry)">
                <i class="fas fa-grip-vertical fa-fw"
                   [title]="'item.edit.bitstreams.edit.buttons.drag' | translate"></i>
              </div>
              {{ entry.name }}
            </th>
            <td class="row-element {{ columnSizes.columns[1].buildClasses() }}"
                headers="{{ entry.nameStripped }} {{ bundleName }} description">
              {{ entry.description }}
            </td>
            <td class="row-element {{ columnSizes.columns[2].buildClasses() }}"
                headers="{{ entry.nameStripped }} {{ bundleName }} format">
              {{ (entry.format | async)?.shortDescription }}
            </td>
            <td class="row-element {{ columnSizes.columns[3].buildClasses() }}"
                headers="{{ entry.nameStripped }} {{ bundleName }} actions">
              <div class="text-center w-100">
                <div class="btn-group relationship-action-buttons">
                  <a [href]="entry.downloadUrl"
                     [attr.aria-label]="'item.edit.bitstreams.edit.buttons.download' | translate"
                     class="btn btn-outline-primary btn-sm"
                     title="{{'item.edit.bitstreams.edit.buttons.download' | translate}}"
                     [attr.data-test]="'download-button' | dsBrowserOnly">
                    <i class="fas fa-download fa-fw"></i>
                  </a>
                  <button [routerLink]="['/bitstreams/', entry.id, 'edit']" class="btn btn-outline-primary btn-sm"
                          [attr.aria-label]="'item.edit.bitstreams.edit.buttons.edit' | translate"
                          title="{{'item.edit.bitstreams.edit.buttons.edit' | translate}}">
                    <i class="fas fa-edit fa-fw"></i>
                  </button>
                  <button [disabled]="!canRemove(update)" (click)="remove(entry.bitstream)"
                          [attr.aria-label]=" 'item. edit bitstreams.edit.buttons.remove' | translate"
                          class="btn btn-outline-danger btn-sm"
                          title="{{'item.edit.bitstreams.edit.buttons.remove' | translate}}">
                    <i class="fas fa-trash-alt fa-fw"></i>
                  </button>
                  <button [disabled]="!canUndo(update)" (click)="undo(entry.bitstream)"
                          [attr.aria-label]="'item.edit.bitstreams.edit.buttons.undo' | translate"
                          class="btn btn-outline-warning btn-sm"
                          title="{{'item.edit.bitstreams.edit.buttons.undo' | translate}}">
                    <i class="fas fa-undo-alt fa-fw"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>

        </tbody>
      </table>

    </ng-container>
  </ds-pagination>

</ng-template>
