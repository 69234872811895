<div class="row">
  <div *ngIf="showThumbnails" class="col-3 col-md-2">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
       [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null" class="dont-break-out">
      <ds-thumbnail [thumbnail]="dso?.thumbnail | async"
                    [defaultImage]="'assets/images/person-placeholder.svg'"
                    [alt]="'thumbnail.person.alt'"
                    [placeholder]="'thumbnail.person.placeholder'">
      </ds-thumbnail>
    </a>
    <span *ngIf="linkType == linkTypes.None" class="dont-break-out">
      <ds-thumbnail [thumbnail]="dso?.thumbnail | async"
                    [defaultImage]="'assets/images/person-placeholder.svg'"
                    [alt]="'thumbnail.person.alt'"
                    [placeholder]="'thumbnail.person.placeholder'">
      </ds-thumbnail>
    </span>
  </div>
  <div [ngClass]="showThumbnails ? 'col-9' : 'col-md-12'">
    <div class="d-flex">
      <div class="flex-grow-1">
        <ds-person-input-suggestions [suggestions]="allSuggestions" [(ngModel)]="selectedName"
                                     (clickSuggestion)="select($event)"
                                     (submitSuggestion)="selectCustom($event)"></ds-person-input-suggestions>
        <span class="text-muted">
          <span *ngIf="dso.allMetadata(['person.jobTitle']).length > 0"
                class="item-list-job-title">
                  <span *ngFor="let value of allMetadataValues(['person.jobTitle']); let last=last;">
                      <span [innerHTML]="value"></span><span *ngIf="!last">; </span>
                  </span>
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
